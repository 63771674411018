import { getItem, postItem, putItem } from '@/api/api-methods';
import { api } from '@/api/axios-base';
const emptyPagination = {
  currentPage: 1,
  lastPage: 1,
  previousPage: 1,
  nextPage: 1,
  offset: 0,
  limit: 5,
  count: 0,
};
export const fetchEmployeeByName = async (
  name = '',
  pagination = emptyPagination,
  systemStatus = true,
  institutionId = null,
  employeeTypeId = null
) => {
  const institutionFilter =
    institutionId !== null ? `?institucion_educativa=${institutionId}` : '';
  const nameFilter =
    name !== '' && name !== null ? `&nombre_completo=${name}` : '';

  const employeeTypeIdFilter = employeeTypeId
    ? `&id_tipo_empleado=${employeeTypeId}`
    : '';
  const systemStatusFilter =
    systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';

  const offsetFilter =
    pagination.offset !== 0 ? `&offset=${pagination.offset}` : '';
  const limitFilter =
    pagination.limit !== null ? `&limit=${pagination.limit}` : '';

  try {
    const URL =
      `/app-personas/helpers/buscar-empleado-por-nombre-completo`.concat(
        institutionFilter,
        nameFilter,
        systemStatusFilter,
        employeeTypeIdFilter,
        offsetFilter,
        limitFilter
      );
    const response = await getItem(`${URL}`);
    // console.log('fetchStudentByName', response);
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo encontrar alumno por nombre. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};
export const fetchEmployee = async ({
  userId = null,
  personalDataId = null,
  taxDataId = null,
  institutionId = null,
  typeEmployee = null,
  typeGroup = null,
  branch = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : '';
    const userIdFilter = userId ? `&usuario=${userId}` : '';
    const personalDataIdFilter =
      personalDataId !== null ? `&datos_personales=${personalDataId}` : '';
    const taxDataIdFilter =
      taxDataId !== null ? `&datos_fiscales=${taxDataId}` : '';
    const typeEmployeeFilter =
      typeEmployee !== null ? `&tipo_empleado=${typeEmployee}` : '';
    const typeGroupFilter =
      typeGroup !== null ? `&titular_grupo=${typeGroup}` : '';
    const branchFilter = branch !== null ? `&sucursal=${branch}` : '';
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-personas/filters/empleado?'.concat(
        institutionFilter,
        userIdFilter,
        personalDataIdFilter,
        taxDataIdFilter,
        typeEmployeeFilter,
        typeGroupFilter,
        branchFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener los proveedores. ' + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener proveedores. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener proveedores. ' + error,
      count: 0,
    };
  }
};

export const fetchEmployeeByEI = async (institutionId) => {
  try {
    const response = await api.get(
      `/app-personas/filters/empleado?institucion_educativa=${institutionId}`
    );
    const responseData = await response.data;
    if (response.status === 200) return responseData;
  } catch (error) {
    console.error(
      'Error al intentar obtener categorias de egresos por institucion educativa',
      error
    );
  }
};
export const fetchAddresById = async (addressId /*, fullAddress*/) => {
  try {
    const responseData = await getItem(`/app-personas/direccion/${addressId}`);
    const {
      calle: ca,
      numero_exterior: ne,
      numero_interior: ni,
      colonia: col,
      ciudad: ci,
      estado: es,
      codigo_postal: cp,
    } = responseData;
    const fullAddress = `${ca} ${ne}${
      ni ? ' ' + ni : ''
    }, Col. ${col}, ${ci}, ${es}. CP. ${cp.codigo_postal}`;
    // console.log('Direccion', fullAddress);
    return fullAddress;
  } catch (error) {
    console.error('Error al obtener direccion por id', error);
  }
};
export const fetchExpensesByEmployee = async ({
  id_empleado,
  fecha_inicio,
  fecha_fin,
  id_institucion_educativa,
}) => {
  // console.log('id_empleado:', id_empleado);
  // console.log('fecha inicio: ', fecha_inicio);
  try {
    const responseData = await postItem(
      '/app-administracion/helpers/get-detalle-egreso-por-empleado',
      {
        id_empleado: id_empleado,
        fecha_inicio: fecha_inicio,
        fecha_fin: fecha_fin,
        id_institucion_educativa: id_institucion_educativa,
      }
    );
    if (responseData.e) {
      this.errors.push(
        'Error al obtener los egresos ' + responseData.e.join(', ')
      );
    }
    // console.log('Respuesta egresos por empleado', responseData);
    return responseData.resultado;
  } catch (error) {
    console.error('Error al obtener egresos por empleado.', error);
  }
};
export const postAddress = async (addresObj) => {
  try {
    const responseData = await api.post('/app-personas/direccion', addresObj);
    // console.log('Respuesta direccion: ', responseData);
    if (responseData.e) {
      this.errors.push(
        'No se pudo crear la dirección: ' + responseData.e.join(',')
      );
      return responseData;
    }
    addresObj.id = responseData.id;
  } catch (error) {
    this.errors.push('Error al intentar crear direccion');
    console.error('Error al intentar crear direccion.', error);
  }
};

export const postAssignTeacherToGroup = async (employeeId, groupId) => {
  const URL = '/app-personas/helpers/asignar-profesor-grupo';
  try {
    const { data } = await postItem(URL, {
      id_empleado: employeeId,
      id_grupo: groupId,
    });
    return { data, ok: true };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};

export const postRemoveTeacherFromGroup = async (employeeId, groupId) => {
  const URL = '/app-personas/helpers/remove-profesores-grupo';
  try {
    const { data } = await postItem(URL, {
      id_profesor: employeeId,
      id_grupo: groupId,
    });
    return { data, ok: true };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};

export const reactivateStudent = async (studentId) => {
  const URL = '/app-personas/helpers/reactivar-alumno';
  try {
    const { data } = await postItem(URL, {
      id_alumno: studentId,
    });
    return { data, ok: true };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};

export const removeEmployee = async (employeeId) => {
  try {
    const { data } = await api.delete(`/app-personas/empleado/${employeeId}`);
    return { data, ok: true };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};
/**
 * Reactiva un empleado
 * @param {number} employeeId Id del empleado a reactivar
 */
export const reactivateEmployee = async (employeeId) => {
  try {
    const { data } = await api.post(
      '/app-personas/helpers/reactivar-empleado',
      { id_empleado: employeeId }
    );
    return { data, ok: true };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};
/**
 * Proceso de creacion de un empleado en una petición
 * 1. Crear datos personales (la dirección se crea aparte)
 * 2. Crear datos fiscales
 * 3. Crear usuario
 * 4. Crear empleado
 * 5. Enviar accesos al correo del usuario
 * @param {Object} employeeData Datos del empleado a crear
 * @returns JSON con confirmacion de la creacion de los objetos involucrados
 */
export const registerEmployee = async (employeeData) => {
  try {
    const responseData = await postItem(
      '/app-personas/helpers/registrar-empleado',
      employeeData
    );
    if (!responseData || responseData.error || responseData.e) {
      return { ok: false, message: responseData.error };
    }
    return { ok: true, data: responseData };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};

/**
 * Actualiza empleado
 * @param {Object} employeeData Informacion del empleado a actualizar. Debe incluir el id
 * @returns Ok true o false
 */
export const putEmployee = async (employeeData) => {
  try {
    const { id: employeeId } = employeeData;
    const responseData = await putItem(
      `/app-personas/empleado/${employeeId}`,
      employeeData
    );
    // console.log('Respuesta empleado: ', responseData);
    if (!responseData || responseData.error || responseData.e) {
      return {
        ok: false,
        message:
          responseData.error ||
          responseData.e ||
          'Error en actualizar empleado',
      };
    }
    return { ok: true, data: responseData };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};

/**
 * Crea grupo especial con su respectivo profesor y alumnos
 * @param {Object} groupData Informacion de grupo especial
 * @returns ok true o false
 */
export const postCreateSpecialGroup = async (groupData) => {
  try {
    const responseData = await postItem(
      '/app-personas/helpers/crear-grupos-especiales',
      groupData
    );
    // console.log('responseData', responseData);

    if (responseData.e || responseData.error) {
      return {
        ok: false,
        message:
          responseData.e ||
          responseData.error ||
          'Error en crear-grupos-especiales',
      };
    }
    return { ok: true, data: responseData };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};

/**
 * Crea grupo especial con su respectivo profesor y alumnos de diferentes grados de un mismo nivel educativo
 * @param {Object} groupData Informacion de grupo especial
 * @returns ok true o false
 */
export const postCreateSpecialGroupV2 = async (groupData) => {
  try {
    const responseData = await postItem(
      '/app-personas/helpers/crear-grupos-especiales-v2',
      groupData
    );
    // console.log('responseData', responseData);

    if (responseData.e || responseData.error) {
      return {
        ok: false,
        message:
          responseData.e ||
          responseData.error ||
          'Error en crear-grupos-especiales multigrado',
      };
    }
    return { ok: true, data: responseData };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};

export const postUpdateEmailUser = async (
  userData = { id_usuario: null, email: null }
) => {
  try {
    const responseData = await postItem(
      '/app-personas/helpers/actualizar-email-usuario',
      userData
    );
    // console.log('responseData', responseData);

    if (responseData.e || responseData.error) {
      return {
        ok: false,
        message:
          responseData.e ||
          responseData.error ||
          'Error al actualizar correo de usuario',
      };
    }
    return { ok: true, data: responseData };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};

export const postUpdateUserTypeAndPermissions = async (
  userData = { id_usuario: null, id_nuevo_tipo_usuario: null }
) => {
  try {
    // console.log('postUpdateUserTypeAndPermissions', userData);

    const responseData = await postItem(
      '/app-personas/helpers/actualizar-tipo-usuario-y-permisos',
      userData
    );
    // console.log('responseData', responseData);

    if (responseData.e || responseData.error) {
      return {
        ok: false,
        message:
          responseData.e ||
          responseData.error ||
          'Error al actualizar tipo de usuario y sus permisos',
      };
    }
    return { ok: true, data: responseData };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};
